$design_width : 720;
$max_width : 720;
$min_width : 320;

html{
  background-color: #ddd;
  font-size: 10000 / $max_width * 1vw;
}


@media screen and ( max-width: $min_width * 1px ) {
  html{
    font-size: $min_width / $max_width * 100px;
  }
}



@media screen and ( min-width: $max_width * 1px ) {
  html{
    font-size: 100px;
  }
}


.wc{
  width: 100%;
  max-width: 1000px;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #ccc;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction:column;
  nav{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #333;
    a{
      flex:1;
      background-color: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.4rem;
      padding: 0.2rem;
      font-weight: 700;
      text-decoration: none;
      color: #333;
      &.active{
        background-color: #444;
        color: #fff;
      }
      // font-size: 60px;
    }
  }

  .body{
    flex:1;
    background-color: #eee;
    font-size: 0.4rem;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}

.test_list{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  > ul{
    list-style: none;
    margin: 0;
    padding: 0;
    > li{
      margin: 0.5rem 0.3rem;
      border: 1px solid #000;
      border-radius: 0.1rem;
      font-size: 0.28rem;
      padding: 0.2rem;
      position: relative;
      line-height: 1.2;
      background-color: #fff;
      .head{
        overflow: hidden;
        .key{
          color: #aaa;
          margin-bottom: 0.2rem;
          display: inline-block;
        }
        .btns{
          position: absolute;
          right: 0.2rem;
          top: -0.3rem;
          display: flex;
          button{
            margin-left: 0.2rem;
            height: 0.8rem;
            width: 0.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.24rem;
            background-color: #eee;
            border-radius: 50%;
            border: solid 1px #333;
            &.active{
              background-color: #fed700;
            }
          }
        }
      }

      h2{
        margin-bottom: 0.2rem;
      }
      h3{
        font-weight: 700;
        margin-bottom: 0.1rem;
      }
      ul.options{
        list-style: disc;
        list-style-position: inside;
        li{
          font-weight: 300;
          color: #999;
          margin-bottom: 0.1rem;
          &:first-child{
            font-weight: 700;
            color: #000;
          }
        }
      }
      .test_image{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.2rem;
        img{
          width: 100%;
          max-width: 50rem;
        }
      }
      .note_edit{
        margin-top: 0.2rem;
        h4{

        }
        textarea{
          border:solid 1px #999;
          width: 100%;
          margin: 0.1rem 0;
          padding: 0.15rem;
          border-radius: 0.1rem;
          background-color: #f9f9f9;
        }
        button{
          width: 100%;
          height: 0.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .note{
        font-size: 0.24rem;
        margin-top: 0.2rem;
        background-color: #def;
        padding: 0.15rem;
        border-radius: 0.1rem;
        h4{
          display: none;
        }
        p{

        }
      }
    }    
  }

}

